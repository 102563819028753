<template>
  <div class="info">
    <h3 class="titleH3">
      {{ user.roleName }} : {{ user.username }}
      <a class="btn" @click="sendInfo" v-if="user.role == 'ADMIN'">站内信息</a>
    </h3>
    <div class="main">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="fromUserName" label="发送人" width="180" row-style />
        <el-table-column prop="toUserName" label="接收人" width="180" row-style />
        <el-table-column prop="createTime" label="发送时间" width="180">
        </el-table-column>
        <el-table-column prop="content" label="消息内容"> </el-table-column>
      </el-table>
      <el-pagination
          @current-change="changePage"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
    <!-- 发送站内信息 -->
    <sendInfo ref="sendInfo" @calback="sendInfoCalback"></sendInfo>
  </div>
</template>

<script>
import sendInfo from "@/views/Manage/components/dialog/sendInfo.vue";
import { getUser } from "@/utils/storage";

import { messages } from "@/api/Mange/Mange";

export default {
  components: {
    sendInfo,
  },
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
      },
      total: 0,
      tableData: [],
      user: {}
    };
  },
  created() {
    this.getList();
    this.user = getUser().user;
  },
  methods: {
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
    sendInfoCalback(){
      this.getList();
    },
    sendInfo() {
      this.$refs.sendInfo.showDialog();
    },
    getList() {
      messages(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total
        }
      });
    },
  },
};
</script>

<style lang="less">
.info {
  // background-color: #0e092b;
  height: 100%;
  .btn {
    width: 104px;
    height: 30px;
    background-color: #d14649;
    color: #3e3131;
    text-align: center;
    line-height: 30px;
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    // .table .el-table tr:hover {
    //   background-color: #000;
    // }
    // .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    //   background-color: rgb(13 13 17);
    //   color: #fff;
    // }
  }
}
</style>
