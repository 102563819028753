<template>
  <div class="school">
    <div class="main">
      <div class="my-search">
        <el-input placeholder="请输入学校名称查询" v-model="queryParams.name" v-if="user.role == 'ADMIN'"/>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="getList" v-if="user.role == 'ADMIN'">查询</el-button>
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd" v-if="user.role == 'ADMIN'">新增</el-button>
      </div>
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="学校名称" row-style />

        <el-table-column label="LOGO" align="center" prop="logo" width="100" row-style>
          <template slot-scope="scope">
            <el-image
                style="width: 100%; height: 100%"
                fit="cover"
                :src="scope.row.logo"
                :preview-src-list="[scope.row.logo]"
            />
          </template>
        </el-table-column>

<!--        <el-table-column prop="isHot" align="center" label="是否热门" width="80" row-style>-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag v-if="scope.row.isHot == 1" type="success" effect="dark">是</el-tag>-->
<!--            <el-tag v-else type="info" effect="dark">否</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="imgUrl" label="学校小图" width="100" align="center" row-style>
          <template slot-scope="scope">
            <el-image
                style="width: 100%; height: 100%"
                fit="cover"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="banners" align="center" label="背景轮播" width="100" row-style>
          <template slot-scope="scope">
            <el-image
                style="width: 100%; height: 100%"
                fit="cover"
                :src="scope.row.banners ? scope.row.banners.split(',')[0] : ''"
                :preview-src-list="scope.row.banners ? scope.row.banners.split(',') : []"
            />
          </template>
        </el-table-column>
        <el-table-column prop="sort" align="center" label="排序" width="60" row-style/>
        <el-table-column prop="areaName" align="center" label="所属省份" row-style/>
        <el-table-column prop="views" align="center" label="浏览量" row-style width="100"/>
        <el-table-column prop="cotrol" label="操作">
          <template slot-scope="scope">
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="text"-->
<!--                icon="el-icon-edit"-->
<!--                @click="handleSetHot(scope.row.id)"-->
<!--            >设置热门</el-button>-->
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="total > 1"
          background
          @current-change="changePage"
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <!-- 添加或修改设备信息对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="学校名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入学校名称" />
        </el-form-item>
        <el-form-item label="所属地区" prop="areaId">
          <el-select v-model="form.areaId" filterable placeholder="请选择地区">
            <el-option
                v-for="item in areaOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="LOGO" prop="logo">
          <el-upload
              :file-list="logo"
              :limit="1"
              :action="uploadImgUrl"
              list-type="picture-card"
              :on-success="handleLogoUploadSuccess"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
<!--        <el-form-item label="是否热门" prop="isHot">-->
<!--          <el-radio-group v-model="form.isHot">-->
<!--            <el-radio :label="1">是</el-radio>-->
<!--            <el-radio :label="0">否</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-form-item>-->
        <el-form-item label="学校小图" prop="imgUrl">
          <el-upload
              :file-list="imgUrl"
              :limit="1"
              :action="uploadImgUrl"
              list-type="picture-card"
              :on-success="handleiImgUrlUploadSuccess"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="背景轮播(最多10个)" prop="banners" label-width="200">
          <el-upload
              :file-list="banners"
              :limit="10"
              :action="uploadImgUrl"
              list-type="picture-card"
              :on-success="handleiBannerUrlUploadSuccess"
              :on-remove="removeBanner"
              :on-preview="handlePictureCardPreview"
              :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
<!--        <el-form-item label="排序" prop="sort">-->
<!--          <el-input-number v-model="form.sort" :min="1" :max="2000"></el-input-number>-->
<!--        </el-form-item>-->
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { schoolList, areaList, setHot, getSchoolById } from "@/api/Mange/Mange";
import { getUser } from "@/utils/storage";
import {saveSchool} from "../../../api/Mange/Mange";

export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
        name: ''
      },
      tableData: [],
      total: 0,
      user: {},
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      logo: [],
      imgUrl: [],
      banners: [],
      // 表单参数
      form: {
        logo: [],
        isHot: 0,
        imgUrl: [],
        banners: [],
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: "学校名称不能为空", trigger: "blur" }
        ],
        areaId: [
          { required: true, message: "所属地区不能为空", trigger: "blur" }
        ],
        logo: [
          { required: true, message: "logo不能为空", trigger: "blur" }
        ],
        isHot: [
          { required: true, message: "是否热门不能为空", trigger: "blur" }
        ],
        imgUrl: [
          { required: true, message: "小图不能为空", trigger: "blur" }
        ],
        banners: [
          { required: true, message: "背景图不能为空", trigger: "blur" }
        ],
        sort: [
          { required: true, message: "排序不能为空", trigger: "blur" }
        ],
      },

      imgVisible: false,
      dialogImageUrl: '',
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/api/upload", // 上传的图片服务器地址

      areaOptions: []
    };
  },
  created() {
    this.getList();
    this.getAreaOptions();
    this.user = getUser().user;
  },
  methods: {
    getList() {
      schoolList(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    getAreaOptions() {
      areaList().then(res => {
        this.areaOptions = res.data;
      })
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加学校信息";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      getSchoolById(row.id).then((response) => {
        this.form = response.data;

        this.logo = this.form.logo ? [{name: '-', url: this.form.logo}] : []
        this.imgUrl = this.form.imgUrl ? [{name: '-', url: this.form.imgUrl}] : []
        this.banners = this.form.banners ? this.form.banners.split(",").map(v => ({ name : v, url: v })) : [];

        this.open = true;
        this.title = "修改学校信息";
      });
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        logo: [],
        isHot: 0,
        imgUrl: [],
        banners: [],
        sort: null,
        areaId: null,
      };
      this.logo = []
      this.imgUrl = []
      this.banners = []
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          // 转换
          if (Array.isArray(this.form.logo)) {
            this.form.logo = this.form.logo.map(e => e.response.data)[0];
          }
          if (Array.isArray(this.form.imgUrl)) {
            this.form.imgUrl = this.form.imgUrl.map(e => e.response.data)[0];
          }
          if (Array.isArray(this.form.banners)) {
            this.form.banners = this.form.banners.map(e => e.response ? e.response.data : e.url).toString()
          }
          saveSchool(this.form).then(res => {
            if (res.status) {
              this.$message.success("保存成功")
              this.open = false;
              this.getList();
            }
          })
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgVisible = true;
    },
    handleLogoUploadSuccess(res, file, fileList) {
      this.form.logo = fileList;
    },
    handleiImgUrlUploadSuccess(res, file, fileList) {
      this.form.imgUrl = fileList;
    },
    handleiBannerUrlUploadSuccess(res, file, fileList) {
      this.form.banners = fileList;
    },
    removeBanner(file, fileList) {
      this.form.banners = fileList;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return false;
      }
      return true;
    },
    handleSetHot(id) {
      setHot({ id }).then(res => {
        if (res.status) {
          this.$message.success("已设置");
          this.getList()
        }
      })
    },
  },
};
</script>

<style lang="less">
.school {
  // background-color: #0e092b;
  height: 100%;
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .my-search {
      display: flex;
      .el-input{
        width: 300px;
        margin-right: 15px;
      }
      .el-input__inner {
        background-color: #28214c;
        height: 32px;
        line-height: 32px;
      }
    }
    // .table .el-table tr:hover {
    //   background-color: #000;
    // }
    // .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    //   background-color: rgb(13 13 17);
    //   color: #fff;
    // }
    .el-input__inner {
      background-color: #0e092b;
      color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
