<template>
  <div class="password">
    <el-dialog
      title="请输入邮箱"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <input
        class="input"
        type="text"
        v-model="ruleForm.email"
        placeholder="请输入邮箱"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateEmail } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        email: "",
      },
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    submitForm() {
      if (this.ruleForm.email.trim() == "") {
        this.$message("请输入邮箱");
      } else {
        updateEmail(this.ruleForm)
          .then((res) => {
            if (res.status) {
              this.$message("修改成功");
              this.$emit("calback", this.ruleForm.email);
            }else{
              this.$message("修改失败");
            }
            this.dialogVisible = false;
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.password {
  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }
  .input {
    padding: 5px 0;
    font-size: 14px;
    border: 1px solid #333;
    background-color: #0e092b;
    color: #fff;
    width: 100%;
    height: 20px;
  }
}
</style>
