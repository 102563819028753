<template>
  <div class="sendInfo">
    <el-dialog
      title="发送站内信息（超级管理员）"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <p>信息范围</p>
      <el-select v-model="ruleForm.toUserId" placeholder="请选择">
        <el-option
          v-for="item in vipOption"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-input class="textarea" type="textarea" v-model="ruleForm.content" placeholder="请在此处编写站内信息"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm">发送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sendmessages,userOptions} from "@/api/Mange/Mange";
export default {
  data() {
    return {
      vipOption:[{label:'所有初创会员',value:'1'},{label:'所有初创会员2',value:'2'}],
      dialogVisible: false,
      ruleForm: {
        toUserId:"",
        content:"",
      },
    };
  },
  created() {

  },
  methods: {
    init(){
      userOptions().then(res=>{
        if(res.status){
          this.vipOption = res.data
        }

      })
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.init()
      this.dialogVisible = true;

    },
    submitForm() {
      if (this.ruleForm.toUserId == "") {
        this.$message("请选择信息范围");
      }else if (this.ruleForm.content.trim() == "") {
        this.$message("请输入站内信息");
      } else {
        sendmessages(this.ruleForm)
          .then((res) => {
            if (res.status) {
              this.$message("发送成功");
              this.$emit("calback");
            }
            this.dialogVisible = false;
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.sendInfo {

    p {
      color: #fff;
      line-height: 30px;
    }
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      border: 1px solid #333;
      background-color: #0e092b;
    }

  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }
  .el-textarea__inner {
    padding: 5px 15px;
    font-size: 14px;
    border: 1px solid #333;
    background-color: #0e092b;
    color: #fff;
    width: 100%;
    height: 80px;
  }

}
</style>
