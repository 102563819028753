<template>
  <div class="username">
    <el-dialog
      title="请输入新昵称"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"

    >
    <input class="input" v-model="ruleForm.username" placeholder="请输入内容"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { userUpdateNickName } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        username: "",
      },
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    submitForm() {
      if (this.ruleForm.username.trim() == "") {
        this.$message("请输入新昵称");
      } else {
        userUpdateNickName(this.ruleForm)
          .then((res) => {
            if(res.status){
              this.$message('修改成功');
              this.$emit('calback',this.ruleForm.username)
            }
            this.dialogVisible = false
            
            
          })
      }
    },
  },
};
</script>

<style lang="less">
.username {
  .el-button{background-color: #d14649;
    color: #3e3131; border: none;}
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 100%;
      height: 20px;
    }
}
</style>
