<template>
  <div class="userAgreement">
    <div class="main">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="用户协议标题" prop="userAgreementTitle">
          <el-input v-model="form.userAgreementTitle"></el-input>
        </el-form-item>
        <el-form-item label="用户协议内容" prop="userAgreementContent">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import { userAgreement, settingsSave } from "@/api/Mange/Mange";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'


export default {
  components: { Editor, Toolbar},
  data() {
    return {
      form: {
        userAgreementTitle: '',
        userAgreementContent: ''
      },
      rules: {
        userAgreementTitle: [
          { required: true, message: "用户协议标题不能为空", trigger: "blur" },
        ],
        userAgreementContent: [
          { required: true, message: "用户协议内容不能为空", trigger: "blur" },
        ],
      },
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: process.env.VUE_APP_BASE_API + "/api/uploadForEditor",
          }
        }
      },
      mode: 'default', // or 'simple'
    };
  },
  created() {
    userAgreement().then(res => {
      this.form.userAgreementTitle = res.data.userAgreementTitle;
      this.form.userAgreementContent = res.data.userAgreementContent;
      this.html = this.form.userAgreementContent;
    })
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    onSubmit() {

      this.form.userAgreementContent = this.html;

      this.$refs["form"].validate(valid => {
        if (valid) {
          settingsSave(this.form).then(() => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          })
        }
      });
    },
  },
};
</script>

<style lang="less">
.userAgreement {
  background-color: #0e092b;
  height: 100%;
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    .table .el-table tr:hover {
      background-color: #000;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgb(13 13 17);
      color: #fff;
    }
    .el-input__inner {
      //background-color: #0e092b;
      //color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
