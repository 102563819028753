<template>
  <el-dialog
    title="校园虚拟导览系统网站视频上传协议"
    :visible.sync="unAuditOpen"
    width="500px"
    :before-close="() => (unAuditOpen = false)"
  >
    <div
      style="height: 400px; line-height: 30px; color: #fff; font-size: 16px; overflow-y: scroll;"
    >
      1.
      部分校园虚拟导览系统网站服务仅向注册用户提供，如果您使用校园虚拟导览系统网站提供的网络存储空间进行导览视频等内容的上传及传播等，请先根据《校园虚拟导览系统网站用户使用协议》及《校园虚拟导览系统网站视频上传协议》和其他校园虚拟导览系统网站规则提示的规则、流程注册成为“视频UP主”，并确保注册信息的真实性、正确性及完整性，如果上述注册信息发生变化，您应及时更改。校园虚拟导览系统网站储存这些用户信息，用于核验用户身份、设置用户权限。
      <br />
      2. 您同意并承诺不从事以下行为：
      <br />
      2.1 冒用他人信息为自己注册校园虚拟导览系统网站账号；
      <br />
      2.2 未经他人合法授权以他人名义注册校园虚拟导览系统网站账号；<br />
      2.3 窃取、盗用他人的校园虚拟导览系统网站账号、视频等； 3.
      对于您在校园虚拟导览系统网站上传或发布的内容，您保证：<br />
      3.1
      对其享有合法的著作权或相应授权，校园虚拟导览系统网站有权展示、散布及推广前述内容。<br />
      3.2
      不冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；<br />
      3.3
      不制作、上传、复制、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他令人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；<br />
      4. 用户在使用校园虚拟导览系统网站服务的过程中，应遵守以下法律法规：<br />
      4.1 《中华人民共和国保守国家秘密法》；<br />
      4.2 《中华人民共和国著作权法》；<br />
      4.3 《中华人民共和国计算机信息系统安全保护条例》；<br />
      4.4 《计算机软件保护条例》；<br />
      4.5 《网络信息内容生态治理规定》<br />
      4.6 《信息网络传播权保护条例》<br />
      4.7 《中华人民共和国网络安全法》；<br />
      4.8 其他法律、法规、规章、条例等具有法律效力的规范。<br />
      5.
      用户需保证其上传内容不得违反广电总局的相关规定，包括但不限于《互联网视听节目服务管理规定》（广电总局56号令）等，其上传节目应当符合法律、行政法规、部门规章的规定，上传内容不得含有以下内容：
      <br />（1） 反对宪法确定的基本原则的；<br />
      （2） 危害国家统一、主权和领土完整的； （3）
      泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；<br />
      （4） 煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；<br />
      （5） 宣扬邪教、迷信的；<br />
      （6） 扰乱社会秩序，破坏社会稳定的； <br />（7）
      诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；<br />
      （8） 侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；<br />
      （9） 危害社会公德，损害民族优秀文化传统的；<br />
      （10） 有关法律、行政法规和国家规定禁止的其他内容。<br />
      6.
      如用户提供的上传内容含有以上违反政策法律法规的信息或者内容的，由用户直接承担以上导致的一切不利后果，如因此给校园虚拟导览系统网站造成不利后果的，用户应负责消除影响，并且赔偿因此导致的一切损失。
      <br />7.
      校园虚拟导览系统网站对于任何包含、经由或连接、下载或从任何与有关本网络服务所获得的任何内容、信息或广告，不声明或保证其正确性或可靠性；并且对于用户经本服务上的广告、展示而购买、取得的任何产品、信息或资料，校园虚拟导览系统网站不负保证责任；对于用户上传视频对视频涉及学校造成负面影响，用户自行负担上传视频的风险。
      <br />8.
      您理解并同意，如您实施违反法律法规、社会公德、公序良俗和/或侵害他人合法权益等行为，并因此影响或可能影响校园虚拟导览系统网站和/或他人和/或学校和/或其他主主体的名誉、声誉或其他合法权益，校园虚拟导览系统网站有权不经通知而单方中断或终止向您提供全部或部分服务。
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      unAuditOpen: false,
    };
  },
  methods: {
    showDialog() {
      this.unAuditOpen = true;
    },
  },
};
</script>

<style></style>
