<template>
  <div class="info">
    <h3 class="titleH3">{{ user.roleName }} : {{ user.username }}</h3>
    <div class="main">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="schoolName" label="学校" row-style> </el-table-column>
        <el-table-column prop="username" label="用户名" row-style/>
        <el-table-column prop="phone" label="手机" row-style width="120"/>
        <el-table-column prop="content" label="申请初创" row-style width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.applyFirst == 1 ? "申请中" : "未申请" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="状态" row-style width="60">
          <template slot-scope="scope">
            <span>{{ scope.row.loginEnable == 1 ? "正常" : "禁止登录" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="注册时间" row-style width="160"/>
        <el-table-column prop="roleName" label="用户组" row-style width="80"/>
        <el-table-column label="上传视频" align="center" width="60" row-style>
          <template slot-scope="scope">
            <span>{{ scope.row.role == 2 ? "否" : "是" }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            align="center"
            width="120"
            row-style
            class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-circle-close"
                v-if="scope.row.loginEnable == 1"
                @click="handleUnAble(scope.row)"
            >禁用</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-circle-check"
                v-if="scope.row.loginEnable == 0"
                @click="handleAble(scope.row)"
            >启用</el-button>

            <el-dropdown
                size="mini"
                @command="(command) => handleCommand(command, scope.row)"
                v-if="(scope.row.applyFirst == 1) || (scope.row.role != 0) || (scope.row.applyFirst != 1 && scope.row.role == 3)"
            >
              <span class="el-dropdown-link" style="color: #409EFF; font-size: 12px">
                <i class="el-icon-d-arrow-right el-icon--right"></i>更多
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  command="handleFirst"
                  icon="el-icon-user"
                  v-if="scope.row.applyFirst == 1"
                >审核初创</el-dropdown-item>
                <el-dropdown-item
                  command="handleAdmin"
                  icon="el-icon-user"
                  v-if="scope.row.role != 0"
                >设置为创始</el-dropdown-item>
                <el-dropdown-item
                    command="handleSetFirst"
                    icon="el-icon-user"
                    v-if="scope.row.applyFirst != 1 && scope.row.role == 3"
                >设置为初创</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="changePage"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { userList, able, unable, setAdmin, setFirst } from "@/api/Mange/Mange";
import { getUser } from "@/utils/storage";

export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
      },
      tableData: [],
      total: 0,
      user: {},
    };
  },
  created() {
    this.getList();
    this.user = getUser().user;
  },
  methods: {
    getList() {
      userList(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    handleCommand(command, row) {
      switch (command) {
        case "handleFirst":
          this.handleFirst(row);
          break;
        case "handleAdmin":
          this.handleAdmin(row);
          break;
        case "handleSetFirst":
          this.handleSetFirst(row);
          break;
        default:
          break;
      }
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
    handleSetFirst(row) {
      const $that = this;

      this.$confirm('是否同意该用户为初创?', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '同意',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setFirst(row.id, 1).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg)
          }
        });
      });
    },
    handleFirst(row) {
      const $that = this;

      this.$confirm("是否同意该用户为初创?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "同意",
        cancelButtonText: "驳回",
        type: "warning",
      })
        .then(() => {
          setFirst(row.id, 1).then((res) => {
            if (res.status) {
              $that.getList();
            } else {
              this.$message.warning(res.msg);
            }
          });
        })
        .catch((action) => {
          if (action === "cancel") {
            setFirst(row.id, 0).then((res) => {
              if (res.status) {
                $that.getList();
              } else {
                this.$message.warning(res.msg);
              }
            });
          }
        });
    },
    handleAdmin(row) {
      const $that = this;
      this.$confirm("设置用户为创始, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        setAdmin(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    handleAble(row) {
      const $that = this;
      this.$confirm("启用用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        able(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    handleUnAble(row) {
      const $that = this;
      this.$confirm("禁用用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        unable(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less">
.info {
  height: 100%;
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;

    // .el-table__body tr.hover-row.current-row > td.el-table__cell,
    // .el-table__body
    //   tr.hover-row.el-table__row--striped.current-row
    //   > td.el-table__cell,
    // .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
    // .el-table__body tr.hover-row > td.el-table__cell {
    //   background-color: rgb(13 13 17);
    //   color: #fff;
    // }
    .el-input__inner {
      background-color: #0e092b;
      color: #fff;
      border: none;
    }
  }
}
</style>
