<template>
  <div class="article">
    <div class="main">
      <div class="my-search">
        <el-input placeholder="请输入文章标题查询" v-model="queryParams.title" clearable/>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="getList">查询</el-button>
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      </div>
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="title" label="标题" row-style />
        <el-table-column prop="author" label="作者" row-style width="100"/>
        <el-table-column prop="views" label="浏览量" width="80"></el-table-column>
        <el-table-column label="类型" align="center" prop="type" width="80" row-style>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 1" type="" effect="plain">内部文章</el-tag>
            <el-tag v-if="scope.row.type == 2" type="warning" effect="plain">外部文章</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否发布" align="center" prop="isPublish" width="100" row-style>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isPublish == 1" type="success">已发布</el-tag>
            <el-tag v-if="scope.row.isPublish == 0" type="warning">未发布</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column prop="publishTime" label="发布时间" width="180"></el-table-column>-->
        <el-table-column prop="sort" label="排序" width="50"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="cotrol" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
            >编辑</el-button>
            <el-button
                v-if="scope.row.isPublish == 0"
                size="mini"
                type="text"
                icon="el-icon-s-promotion"
                @click="handlePublish(scope.row)"
            >发布</el-button>
            <el-button
                v-if="scope.row.isPublish == 1"
                size="mini"
                type="text"
                icon="el-icon-lock"
                @click="handleCancelPublish(scope.row)"
            >取消发布</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            @current-change="changePage"
            background
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 添加或修改设备信息对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="1000px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入文章标题" />
        </el-form-item>
        <el-form-item label="作者" prop="author">
          <el-input v-model="form.author" placeholder="请输入文章作者" />
        </el-form-item>
        <el-form-item label="文章类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">内部文章</el-radio>
            <el-radio :label="2">外链文章</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="文章内容" prop="content" v-if="form.type == 1">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>

        <el-form-item label="文章链接" prop="linkUrl" v-if="form.type == 2">
          <el-input v-model="form.linkUrl" placeholder="请输入文章链接" />
        </el-form-item>

        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="form.sort" :min="1"></el-input-number>
        </el-form-item>
        <el-form-item label="浏览量" prop="views">
          <el-input-number v-model="form.views" :min="1"></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { articleList, articleDetail, articleSave, articlePublish, articleCancelPublish, articleDelete } from "@/api/Mange/Mange";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar},
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
        title: ''
      },
      total: 0,
      tableData: [],
      open: false,
      title: '',
      form: {},
      // 表单校验
      rules: {
        title: [
          { required: true, message: "文章标题不能为空", trigger: "blur" }
        ],
        author: [
          { required: true, message: "文章作者不能为空", trigger: "blur" }
        ],
        type: [
          { required: true, message: "文章类型不能为空", trigger: "blur" }
        ],
        sort: [
          { required: true, message: "文章排序不能为空", trigger: "blur" }
        ],
        views: [
          { required: true, message: "初始浏览量不能为空", trigger: "blur" }
        ],
        linkUrl: [
          {required: true, message: "文章链接不能为空", trigger: "blur"}
        ],
        content: [
          {required: true, message: "文章内容不能为空", trigger: "blur"}
        ],
      },
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: process.env.VUE_APP_BASE_API + "/api/uploadForEditor",
          }
        }
      },
      mode: 'default', // or 'simple'
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
    getList() {
      articleList(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total
        }
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加学校信息";
    },
    handleUpdate(row) {
      this.reset();
      articleDetail(row.id).then((response) => {
        this.form = response.data;
        this.html = this.form.content;
        this.open = true;
        this.title = "修改学校信息";
      });
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        title: '',
        type: 1,
        linkUrl: '',
        content: '',
        sort: 100,
        views: 100
      };
      this.html = ''
    },
    cancel() {
      this.open = false;
      this.reset();
    },
    /** 提交按钮 */
    submitForm() {

      this.form.content = this.html;

      this.$refs["form"].validate(valid => {
        if (valid) {
          articleSave(this.form).then(res => {
            if (res.status) {
              this.$message.success("保存成功")
              this.open = false;
              this.getList();
            }
          })
        }
      });
    },
    handlePublish(row) {
      const $that = this;
      this.$confirm("发布文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        articlePublish(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    handleCancelPublish(row) {
      const $that = this;
      this.$confirm("取消发布文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        articleCancelPublish(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
    handleDelete(row) {
      const $that = this;
      this.$confirm("删除文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        articleDelete(row.id).then((res) => {
          if (res.status) {
            $that.getList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less">
.article {
  .my-search {
    display: flex;
    .el-input{
      width: 300px;
      margin-right: 15px;
    }
    .el-input__inner {
      background-color: #28214c;
      height: 32px;
      line-height: 32px;
    }
  }
  // background-color: #0e092b;
  height: 100%;
  .btn {
    width: 104px;
    height: 30px;
    background-color: #d14649;
    color: #3e3131;
    text-align: center;
    line-height: 30px;
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
    margin-left: 10px;
    cursor: pointer;
  }
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    // .table .el-table tr:hover {
    //   background-color: #000;
    // }
    // .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    //   background-color: rgb(13 13 17);
    //   color: #fff;
    // }
    .page {
      text-align: right;
      padding: 10px;
    }
  }
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
