<template>
  <div class="Mange">
    <el-row>
      <el-col class="mennu" :span="4" :style="getHeight()">
        <el-menu
          default-active="personalInfo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#3D3B4D"
          text-color="#fff"
          active-text-color="#ffd04b"
          @select="tomenu"
        >
          <el-submenu index="1">
            <template slot="title">
              <span>用户管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="personalInfo">个人信息</el-menu-item>
              <el-menu-item index="videoUpload" v-if="user.role != 'NOMAL'" >视频上传</el-menu-item>
              <el-menu-item index="videoList" v-if="user.role != 'NOMAL'">视频管理</el-menu-item>
              <el-menu-item index="memberManagement" v-if="user.role == 'ADMIN'">成员管理</el-menu-item>
              <el-menu-item index="schoolManagement" v-if="user.role == 'ADMIN' || user.role == 'FIRST'">学校管理</el-menu-item>
              <el-menu-item index="qaManagement" v-if="user.role == 'ADMIN'">反馈信息</el-menu-item>
              <el-menu-item index="systemSettins" v-if="user.role == 'ADMIN'">系统设置</el-menu-item>
              <el-menu-item index="userAgreement" v-if="user.role == 'ADMIN'">用户协议</el-menu-item>
              <el-menu-item index="timeLine" v-if="user.role == 'ADMIN'">时间轴设置</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="stationInfo">
            <span slot="title">站内信息</span>
          </el-menu-item>
          <el-menu-item index="articleInfo">
            <span slot="title">文章管理</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <component :is="currentComponet"></component>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import personalInfo from "@/views/Manage/components/personalInfo.vue";
import videoUpload from "@/views/Manage/components/videoUpload.vue";
import videoList from "@/views/Manage/components/videoList.vue";
import memberManagement from "@/views/Manage/components/memberManagement.vue";
import schoolManagement from "@/views/Manage/components/schoolManagement.vue";
import qaManagement from "@/views/Manage/components/qaManagement.vue";
import systemSettins from "@/views/Manage/components/systemSettins.vue";
import userAgreement from "@/views/Manage/components/userAgreement.vue";
import timeLine from "@/views/Manage/components/timeLine.vue";
import stationInfo from "@/views/Manage/components/stationInfo.vue";
import articleInfo from "@/views/Manage/components/articleInfo.vue";

import { getUser } from "@/utils/storage";

export default {
  name: "Mange",
  components: {
    personalInfo,
    videoUpload,
    videoList,
    memberManagement,
    schoolManagement,
    stationInfo,
    articleInfo,
    qaManagement,
    systemSettins,
    userAgreement,
    timeLine
  },
  data() {
    return {
      schoolsOptions: [],
      currentComponet: "personalInfo",
      height: window.innerHeight - 60,
      user: null,
    };
  },
  created() {
    this.user = getUser().user;
  },
  methods: {
    tomenu(key, keyPath) {
      if (key == "stationInfo") {
        this.currentComponet = keyPath[0];
      } else if (key == "articleInfo") {
        this.currentComponet = keyPath[0];
      } else {
        this.currentComponet = keyPath[1];
      }
    },
    getHeight() {
      let css = `height:${this.height}px;background-color: #3D3B4D;`;
      return css;
    },
    menuJump(path) {
      this.$router.push({ path: path });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
<style lang="less">
.Mange {
  .el-menu {
    border-right: none;
  }
}
</style>
