<template>
  <div class="videoUpload">
    <h3 class="titleH3">{{ user.roleName }} : {{ user.username }}</h3>
    <div class="main">
      <ul>
        <li>
          <p class="title">是否风景视频</p>
          <el-select v-model="ruleForm.isCollege" placeholder="请选择视频类型">
            <el-option label="风景" :value="0"></el-option>
            <el-option label="院系" :value="1"></el-option>
          </el-select>
        </li>
        <li>
          <p class="title">上传视频涉及的学校</p>
          <el-select v-model="ruleForm.schoolId" filterable placeholder="请选择">
            <el-option
                v-for="item in schoolsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li v-if="ruleForm.isCollege == 1">
          <p class="title">标题</p>
          <input class="input" v-model="ruleForm.title" />
        </li>
        <li v-if="ruleForm.isCollege == 0">
          <p class="title">起点名称</p>
          <input class="input" v-model="ruleForm.startName" />
        </li>
        <li  v-if="ruleForm.isCollege == 0">
          <p class="title">终点名称</p>
          <input class="input" type="text" v-model="ruleForm.endName" />
        </li>

        <li  v-if="ruleForm.isCollege == 0">
          <p class="title">其他途径点</p>
          <input class="input" type="text" v-model="ruleForm.middleName" />
        </li>
        <li>
          <p class="title">备注</p>
          <input class="input" type="text" v-model="ruleForm.description" />
        </li>
        <li>
          <el-upload
              :limit="1"
              :data="uploadData"
              :before-upload="handleBefore"
              :on-success="handleUploadSuccess"
              class="upload-demo"
              :action="uploadData.host"
              :on-exceed="handleExceed"
          >
            <el-button style="background-color: #d14649; border: none; color: #3e3131" size="small" type="primary">文件管理器选择</el-button>
            <div slot="tip" class="el-upload__tip">只能上传x264.mp4格式视频，且不超过1G</div>
          </el-upload>
        </li>
<!--        <li>-->
<!--          <el-upload-->
<!--            ref="upload"-->
<!--            class="upload-demo"-->
<!--            action="#"-->
<!--            :show-file-list="true"-->
<!--            :limit="1"-->
<!--            :http-request="httpRequest"-->
<!--          >-->
<!--            <el-button class="btn1" size="small" type="primary"-->
<!--              >文件管理器选择</el-button-->
<!--            >-->
<!--          </el-upload>-->
<!--        </li>-->
        <li>
          <a class="btn" @click="submitForm">提交视频</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { videos, upload,schoolsOptions, getUploadConfig} from "@/api/Mange/Mange";
import { getUser } from "@/utils/storage";

export default {
  data() {
    return {
      ruleForm: {
        title: "", //视频标题
        type: 1, //视频类型(
        description: "", //描述
        coverPath: "", //封面
        videoPath: "", //视频链接
        isCollege: 0, //是否院系
        isCycling: 1, //是否骑行
        startName: "", //起始点
        middleName: "", //途经点
        endName: "", //结束点
      },
      schoolsOptions:[],
      user: {},
      uploadData: {
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: 200,
        signature: '',
        host: '',
        dir: '',
      },
    };
  },
  created() {
    this.getShoolsOption();
    this.user = getUser().user;
  },
  methods: {
    getShoolsOption() {
      schoolsOptions().then((res) => {
        if (res.status) {
          this.schoolsOptions = res.data;
        } else {
          this.$message(res.msg);
        }
      });
    },
    httpRequest(file) {
      let fileFormData = new FormData();
      fileFormData.append("file", file.file);
      upload(fileFormData).then((res) => {
        if (res.status) {
          this.ruleForm.videoPath = res.data;
        }
        this.$message(res.msg);
      });
    },
    submitForm() {
      this.videoUpload();
    },
    videoUpload() {
      videos(this.ruleForm)
        .then((res) => {
          if(res.status){
            this.$message.success('保存视频成功');
            this.resetForm();
          }else{
            this.$message.warning(res.msg);
          }
        })
        .catch(() => {
          this.$toast("获取服务器认证失败");
        }); //捕获异常
    },
    resetForm() {
      this.ruleForm = {
        title: "", //视频标题
        type: 1, //视频类型(
        description: "", //描述
        coverPath: "", //封面
        videoPath: "", //视频链接
        isCollege: 0, //是否院系
        isCycling: 1, //是否骑行
        startName: "", //起始点
        middleName: "", //途经点
        endName: "", //结束点
      }
      this.$refs.upload.clearFiles();
    },
    async handleBefore(file) {
      if ((file.size / 1024 / 1024) > 1024) {
        this.$message.error('上传视频大小不能超过 1G!');
        return false;
      }
      if (file.type !== 'video/mp4') {
        this.$message.error('只能上传mp4格式视频');
        return false;
      }

      const res = await getUploadConfig();
      this.uploadData.policy = res.policy;
      this.uploadData.OSSAccessKeyId = res.ossaccessKeyId;
      this.uploadData.signature = res.signature;
      this.uploadData.host = res.host;

      const now = new Date();
      this.uploadData.dir = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}/${now.getTime()}/`;
      this.uploadData.key = this.uploadData.dir + '${filename}'

      console.log(this.uploadData)
    },
    handleUploadSuccess(res, file) {
      this.ruleForm.videoPath = this.uploadData.host + this.uploadData.dir + file.name;
      this.$message.success("上传成功");
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  },
};
</script>

<style lang="less">
.videoUpload {
  height: 100%;

}
</style>
