<template>
  <div class="personalInfo">
    <h3 class="titleH3">{{ user.roleName }} : {{ user.username }}</h3>
    <div class="main">
      <p class="title">用户名</p>
      <input
        class="input"
        v-model="ruleForm.nickName"
        :disabled="codeDisabled"
      />
      <el-button class="btnCode" @click="editName"
        >修改昵称<span>{{ time }}</span></el-button
      >
      <p class="title">你的邮箱</p>
      <input
        class="input"
        type="text"
        v-model="ruleForm.email"
        :disabled="codeDisabled"
      />
      <el-button class="btnCode" @click="editEmail"
        >修改绑定邮箱<span>{{ time }}</span></el-button
      >
      <p class="title">你的手机</p>
      <input
        class="input"
        type="text"
        v-model="ruleForm.phone"
        :disabled="codeDisabled"
      />
      <el-button class="btnCode" @click="editIphone"
        >修改手机绑定<span>{{ time }}</span></el-button
      >
      <el-button class="btnCode" @click="editPassword"
        >修改密码<span>{{ time }}</span></el-button
      >
      <p class="title">视频上传权限</p>
      <input
        class="input"
        :value="user.role === 'NOMAL' ? '未开通' : '已开通'"
        :disabled="codeDisabled"
      />
      <el-button class="btnCode" @click="editEnablevideoupload"
        >开通视频上传权限<span>{{ time }}</span></el-button
      >
    </div>
    <!-- 修改昵称 -->
    <username ref="username" @calback="usernameCalback"></username>
    <!-- 修改密码 -->
    <password ref="password"></password>
    <!-- 修改手机号 -->
    <iphone ref="iphone" @calback="iphoneCalback"></iphone>
    <!-- 修改邮箱 -->
    <email ref="email" @calback="emailCalback"></email>
    <!-- 修改密码 -->
    <enablevideoupload ref="enablevideoupload"></enablevideoupload>
  </div>
</template>

<script>
import username from "@/views/Manage/components/dialog/username.vue";
import password from "@/views/Manage/components/dialog/password.vue";
import iphone from "@/views/Manage/components/dialog/iphone.vue";
import email from "@/views/Manage/components/dialog/email.vue";
import enablevideoupload from "@/views/Manage/components/dialog/enablevideoupload.vue";
import { getUser } from "@/utils/storage";

import { logoutStatic } from "@/api/Login/Login";
export default {
  components: {
    username,
    password,
    enablevideoupload,
    iphone,
    email
  },
  data() {
    return {
      codeDisabled: true,
      time: "",
      ruleForm: {
        email: "",
        nickName: "",
        phone: "",
        code: "",
        password: "",
        againPassword: "",
        user: {}
      },
    };
  },
  created() {
    this.init();
    this.user = getUser().user;
  },
  methods: {
    init() {
      let user = getUser().user;
      if (user) {
        this.ruleForm.nickName = user.username;
        this.ruleForm.email = user.email;
        this.ruleForm.phone = user.phone;
      }
    },
    usernameCalback(val) {
      this.ruleForm.nickName = val;
    },
    iphoneCalback(val) {
      this.ruleForm.iphone = val;
    },
    editName() {
      this.$refs.username.showDialog();
    },
    editPassword() {
      this.$refs.password.showDialog();
    },
    editIphone() {
      this.$refs.iphone.showDialog();
    },
    emailCalback(val) {
      this.ruleForm.email = val;
    },
    editEmail() {
      this.$refs.email.showDialog();
    },
    editEnablevideoupload() {
      this.$refs.enablevideoupload.showDialog();
    },
    getCode() {},
    submitForm() {
      if (this.ruleForm.name.trim() == "") {
        this.$message("请输入用户名");
      } else if (
        this.ruleForm.tel.trim() == "" ||
        this.ruleForm.tel.length != 11
      ) {
        this.$message("请输入手机号");
      } else if (this.ruleForm.code.trim() == "") {
        this.$message("请输入短信验证码");
      } else if (this.ruleForm.password.trim() == "") {
        this.$message("请输入密码");
      } else if (this.ruleForm.againPassword.trim() == "") {
        this.$message("请再次输入密码");
      } else if (
        this.ruleForm.password.trim() != this.ruleForm.againPassword.trim()
      ) {
        this.$message("密码不一致");
      } else {
        this.staticlogin();
      }
    },
    staticlogin() {
      logoutStatic(this.loginPramstatic)
        .then((res) => {
          if (res.code == "201") {
            this.$message(res.message);
            return;
          } else {
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$message("获取服务器认证失败");
        }); //捕获异常
    },
  },
};
</script>

<style lang="less">
.personalInfo {
  height: 100%;
  // .titleH3 {
  //   color: #fff;
  //   font-size: 56px;
  //   font-weight: normal;
  //   padding: 1px 0 20px 0;
  //   margin-left: 85px;
  //   margin-top: 80px;
  // }
  // .main {
  //   margin-left: 60px;
  //   width: 800px;
  //   border: 1px solid #000;
  //   padding-left: 30px;
  //   padding-top: 20px;
  //   padding-bottom: 20px;

  //   .title {
  //     padding: 5px 0;
  //     font-size: 14px;
  //     color: #fff;
  //   }
  //   .input {
  //     padding: 5px 0;
  //     font-size: 14px;
  //     border: 1px solid #333;
  //     background-color: #0e092b;
  //     color: #fff;
  //     width: 550px;
  //     height: 20px;
  //   }
  //   .btn {
  //     width: 80px;
  //     height: 20px;
  //     font-size: 14px;
  //     background-color: #d14649;
  //     color: #3e3131;
  //     text-align: center;
  //     line-height: 20px;
  //     display: block;
  //     margin: 0 auto;
  //     float: right;
  //     margin-top: 21px;
  //     margin-right: 20px;
  //   }
  //   .btnCode {
  //     width: 150px;
  //     height: 20px;
  //     font-size: 14px;
  //     background-color: #646464;
  //     color: #fff;
  //     text-align: center;
  //     line-height: 1px;
  //     display: block;
  //     margin: 0 auto;
  //     float: right;
  //     border: none;
  //     border-radius: none;

  //     margin-top: 2px;
  //     margin-right: 20px;
  //   }
  // }
}
</style>
