<template>
  <div class="iphone">
    <el-dialog
      title="请输入手机号"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <p class="title">手机号码</p>
      <input class="inputTel" type="text" v-model="ruleForm.phone" /><el-button
        class="btnCode"
        :disabled="codeDisabled"
        @click="getCode"
        >获取验证码<span>{{ time }}</span></el-button
      >
      <p class="title">短信验证码</p>
      <input class="input" v-model="ruleForm.smsCode" />
      <p class="title">新手机号</p>
      <input
        class="input"
        type="text"
        v-model="ruleForm.newPhone"
        placeholder="请输入密码"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { phoneCode } from "@/api/Login/Login";

import { userUpdatePhone } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      codeDisabled: false,
      time: "",
      dialogVisible: false,
      ruleForm: {
        phone: "",
        smsCode: "",
        newPhone: "",
      },
    };
  },
  created() {},
  methods: {
    getCode() {
      this.codeDisabled = true;
      phoneCode(this.ruleForm.phone).then((res) => {
        if (!res.status) {
          this.$message(res.msg);
        }
      });
      let time = 60;
      this.time = `(${time}s)`;
      let timer = setInterval(() => {
        time--;
        this.time = `(${time}s)`;
        if (time == 0) {
          this.codeDisabled = false;
          this.time = "";
          clearInterval(timer);
        }
      }, 1000);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    submitForm() {
      if (
        this.ruleForm.phone.trim() == "" ||
        this.ruleForm.phone.length != 11
      ) {
        this.$message("请输入手机号");
      } else if (this.ruleForm.smsCode.trim() == "") {
        this.$message("请输入短信验证码");
      } else if (this.ruleForm.newPhone.trim() == "") {
        this.$message("请输入新手机号");
      } else {
        userUpdatePhone(this.ruleForm)
          .then((res) => {
            if (res.status) {
              this.$message("修改成功");
              this.$emit("calback", this.ruleForm.newPhone);
            }else{
              this.$message("修改失败");
            }
            this.dialogVisible = false;
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.iphone {
  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }
  .title {
    padding: 5px 0;
    font-size: 14px;
    color: #fff;
  }
  .input {
    padding: 5px 0;
    font-size: 14px;
    border: 1px solid #333;
    background-color: #0e092b;
    color: #fff;
    width: 100%;
    height: 20px;
  }
  .inputTel {
    padding: 5px 0;
    font-size: 14px;
    border: 1px solid #333;
    background-color: #0e092b;
    color: #fff;
    width: 250px;
    height: 20px;
  }
  .btnCode {
    width: 130px;
    height: 20px;
    font-size: 14px;
    background-color: #646464;
    color: #fff;
    text-align: center;
    line-height: 0;
    display: block;
    margin: 0 auto;
    float: right;
    border: none;
    border-radius: none;

    margin-top: 2px;
    margin-right: 20px;
  }
}
</style>
