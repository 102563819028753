<template>
  <div class="qaList">
    <div class="main">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="phone" label="联系方式" row-style />
        <el-table-column prop="content" label="内容" row-style />
        <el-table-column prop="createTime" label="反馈时间" width="160" row-style/>
        <el-table-column prop="cotrol" label="操作" width="250">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { qaList, deleteQa } from "@/api/Mange/Mange";
import { getUser } from "@/utils/storage";

export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
      },
      tableData: [],
      total: 0,
      user: {},
    };
  },
  created() {
    this.getList();
    this.user = getUser().user;
  },
  methods: {
    handleDelete(id) {
      const $that = this;
      this.$confirm('删除反馈信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteQa(id).then((res) => {
          this.$message(res.msg);
          $that.getList();
        });
      })
    },
    getList() {
      qaList(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
  },
};
</script>

<style lang="less">
.qaList {
  background-color: #0e092b;
  height: 100%;
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    .table .el-table tr:hover {
      background-color: #000;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgb(13 13 17);
      color: #fff;
    }
    .el-input__inner {
      background-color: #0e092b;
      color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
