<template>
  <div class="password">
    <el-dialog
      title="请输入新密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"

      
    >
    <input class="input" type="password" v-model="ruleForm.newPsw" placeholder="请输入密码"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitForm">确认修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updatePassword } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        newPsw: "",
      },
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    submitForm() {
      if (this.ruleForm.newPsw.trim() == "") {
        this.$message("请输入新密码");
      } else {
        updatePassword(this.ruleForm)
          .then((res) => {
            this.$message(res.msg);

            
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.password {
  .el-button{background-color: #d14649;
    color: #3e3131; border: none;}
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 100%;
      height: 20px;
    }
}
</style>
