<template>
  <div class="videoList">
    <h3 class="titleH3">{{ user.roleName }} : {{ user.username }}</h3>
    <div class="main">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column prop="schoolName" label="学校名称" row-style />
        <el-table-column prop="title" label="标题" row-style />
        <el-table-column prop="createTime" label="上传时间" width="160" row-style>
        </el-table-column>
        <el-table-column prop="username" label="上传者" row-style width="120">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="90">
          <template slot-scope="scope">
            <div v-if="scope.row.status === 3">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.unpassReason">
                <span slot="reference">{{ scope.row.auditStatus }}</span>
              </el-popover>
            </div>
            <span v-else>{{ scope.row.delFlag == 1 ? '已删除' : scope.row.auditStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cotrol" label="操作" width="250">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-video-play"
                @click="handlePlay(scope.row)"
            >播放</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row.id)"
            >编辑</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-if="scope.row.delFlag == 0"
                @click="deleteVideo(scope.row.id)"
            >删除</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                v-if="scope.row.delFlag == 1"
                @click="revokeVideo(scope.row.id)"
            >恢复</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-circle-check"
                v-if="scope.row.delFlag == 0 && scope.row.status == 1 && (user.role == 'ADMIN' || user.role == 'FIRST')"
                @click="auditVideo(scope.row.id)"
            >通过</el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-circle-close"
                v-if="scope.row.delFlag == 0 && scope.row.status == 1 && (user.role == 'ADMIN' || user.role == 'FIRST')"
                @click="handleOpenUnAudit(scope.row.id)"
            >不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        title="视频"
        :visible.sync="playVisible"
        width="500"
        @opened="play"
        :before-close="handleClose">
      <div id="mse"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="选择未通过原因"
        :visible.sync="unAuditOpen"
        width="300"
        :before-close="() => unAuditOpen = false">
      <div class="reason-row"><el-radio v-model="reason" :label="1">1. 违反法律规定（涉及血腥/色情/暴恐/反动/封建迷信/邪教；危害国家安全；散布谣言）</el-radio></div>
      <div class="reason-row"><el-radio v-model="reason" :label="2">2. 可能存在侵权（侵犯著作权、个人隐私、学校名誉）</el-radio></div>
      <div class="reason-row"><el-radio v-model="reason" :label="3">3. 错误引导（危险行为、危害未成年人、引战）</el-radio></div>
      <div class="reason-row"><el-radio v-model="reason" :label="4">4. 内容与网站宗旨无关</el-radio></div>
      <div class="reason-row"><el-radio v-model="reason" :label="5">5. 文件格式错误（无法播放、花屏、黑屏）</el-radio></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="unAuditOpen = false">取 消</el-button>
        <el-button type="primary" @click="unAuditVideo">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="编辑视频"
        :visible.sync="open"
        width="500"
        :before-close="() => open = false">
      <el-form :model="form" size="mini" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <el-form-item label="视频类型" prop="isCollege">
          <el-select v-model="form.isCollege" placeholder="请选择活动区域">
            <el-option label="院系" :value="1"/>
            <el-option label="风景" :value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学校" prop="schoolId">
          <el-select v-model="form.schoolId" placeholder="请选择">
            <el-option
                v-for="item in schoolsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.isCollege == 1" label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item v-if="form.isCollege == 0" label="起点名称" prop="startName">
          <el-input v-model="form.startName"></el-input>
        </el-form-item>
        <el-form-item v-if="form.isCollege == 0" label="终点名称" prop="endName">
          <el-input v-model="form.endName"></el-input>
        </el-form-item>
        <el-form-item v-if="form.isCollege == 0" label="其他途径点" prop="middleName">
          <el-input v-model="form.middleName"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetForm()">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm()">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { videosManageList, videosDetail, schoolsOptions, videosManageDelete,videosManageAudit, videosManageRevoke} from "@/api/Mange/Mange";

import { getUser } from "@/utils/storage";
import Player from "xgplayer";
import {videosManageUpdate} from "../../../api/Mange/Mange";

export default {
  data() {
    return {
      queryParams: {
        pageNo: 1,
        size: 10,
      },
      tableData: [],
      total: 0,
      user: {},
      playVisible: false,
      player: null,
      videoPath: '',
      unAuditOpen: false,
      unAuditVideoId: '',
      reason: '',
      form: {
        id: null
      },
      rules: {
        isCollege: [
          {required: true, message: '请输入视频类型', trigger: 'blur'},
        ],
        schoolId: [
          {required: true, message: '请输入所属学校', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入视频标题', trigger: 'blur'},
        ],
        startName: [
          {required: true, message: '请输入起点名称', trigger: 'blur'},
        ],
        endName: [
          {required: true, message: '请输入终点名称', trigger: 'blur'},
        ],
        middleName: [
          {required: true, message: '请输入其他途径点', trigger: 'blur'},
        ],

      },
      open: false,
      schoolsOptions:[],
    };
  },
  created() {
    this.getList();
    this.user = getUser().user;
  },
  methods: {
    handleUpdate(videoId) {
      this.open = true;
      this.getShoolsOption();
      videosDetail(videoId).then(res => {
        this.form = res.data;
      });
    },
    resetForm() {
      this.open = false;
      this.$refs['form'].resetFields();
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          videosManageUpdate(this.form).then(res => {
            if (res.status) {
              this.$message.success(res.msg);
              this.open = false;
              this.getList();
            } else {
              this.$message.warning(res.msg);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getShoolsOption() {
      schoolsOptions().then((res) => {
        if (res.status) {
          this.schoolsOptions = res.data;
        } else {
          this.$message(res.msg);
        }
      });
    },
    deleteVideo(videoId) {
      const $that = this;
      this.$confirm('删除视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        videosManageDelete(videoId).then((res) => {
          this.$message(res.msg);
          $that.getList();
        });
      })
    },
    revokeVideo(videoId) {
      const $that = this;
      this.$confirm('恢复删除的视频, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        videosManageRevoke(videoId).then((res) => {
          this.$message(res.msg);
          $that.getList();
        });
      })
    },
    auditVideo(videoId) {
      const $that = this;
      this.$confirm('确认视频审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        videosManageAudit(videoId, 2).then((res) => {
          this.$message(res.msg);
          $that.getList();
        });
      })
    },
    handleOpenUnAudit(videoId) {
      this.unAuditVideoId = videoId;
      this.unAuditOpen = true;
    },
    unAuditVideo() {
      videosManageAudit(this.unAuditVideoId, 3, this.reason).then((res) => {
        if (res.status) {
          this.$message.success(res.msg);
          this.unAuditVideoId = '';
          this.unAuditOpen = false;
          this.reason = '';
          this.getList();
        } else {
          this.$message.warning(res.msg)
        }
      });
    },
    getList() {
      videosManageList(this.queryParams).then((res) => {
        if (res.status) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getList();
    },
    handleClose() {
      this.playVisible = false;
      this.player.destroy(true)
    },
    handlePlay(row) {
      this.playVisible = true;
      this.videoPath = row.videoPath;
    },
    play() {
      this.player = new Player({
        id: 'mse',
        url: this.videoPath
      });
    },
  },
};
</script>

<style lang="less">
.videoList {
  // background-color: #0e092b;
  height: 100%;
  // .titleH3 {
  //   color: #fff;
  //   font-size: 56px;
  //   font-weight: normal;
  //   padding: 1px 0 20px 0;
  //   margin-left: 85px;
  //   margin-top: 80px;
  // }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;

    // .table .el-table tr:hover {
    //   background-color: #000;
    // }
    // .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    //   background-color: rgb(13 13 17);
    //   color: #fff;
    // }
    .el-input__inner {
      background-color: #0e092b;
      color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
