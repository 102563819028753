<template>
  <div class="userAgreement">
    <div class="main">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="时间轴标题" prop="timeTitle">
          <el-input v-model="form.timeTitle"></el-input>
        </el-form-item>

        <el-form-item size="mini" label="时间轴内容" prop="timeContent">
          <el-button type="primary" icon="el-icon-plus" round @click="handleAdd" size="mini" style="margin-bottom: 10px;">新增</el-button>
          <el-row :gutter="8" :key="index" v-for="(item, index) of contentList">
            <el-col :span="6">
              <el-input v-model="item.timestamp"></el-input>
            </el-col>
            <el-col :span="16">
              <el-input v-model="item.content"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button type="danger" icon="el-icon-delete" @click="deleteItem(index)" circle></el-button>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import { userAgreement, settingsSave } from "@/api/Mange/Mange";

export default {
  data() {
    return {
      form: {
        timeTitle: '',
        timeContent: '',
      },
      contentList: [],
      rules: {
        timeTitle: [
          { required: true, message: "标题不能为空", trigger: "blur" },
        ],
        timeContent: [
          { required: true, message: "内容不能为空", trigger: "blur" },
        ],
      }
    };
  },
  created() {
    userAgreement().then(res => {
      this.form.timeTitle = res.data.timeTitle;
      this.form.timeContent = res.data.timeContent;
      this.contentList = JSON.parse(res.data.timeContent);
    })
  },
  methods: {
    handleAdd() {
      this.contentList.unshift({
        timestamp: '',
        content: ''
      })
    },
    deleteItem(index) {
      this.contentList.splice(index, 1);
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.timeContent = JSON.stringify(this.contentList);
          settingsSave(this.form).then(() => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          })
        }
      });
    },
  },
};
</script>

<style lang="less">
.el-row {
  margin-bottom: 10px;
}
.userAgreement {
  background-color: #0e092b;
  height: 100%;
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    .table .el-table tr:hover {
      background-color: #000;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgb(13 13 17);
      color: #fff;
    }
    .el-input__inner {
      //background-color: #0e092b;
      //color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
