<template>
  <div class="password">
    <el-dialog
      title="是否愿意成为本校初创会员"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="box">
        <div style="display: flex; justify-content: space-around">
          <el-button type="primary" plain size="small" @click="handleApplyFirst">是</el-button>
          <el-button type="info" plain size="small" @click="handleClose">否</el-button>
        </div>
      </div>
      <div class="box1">
        <el-checkbox v-model="agreeChecked"></el-checkbox>
        <span class="text">我已阅读并同意<a class="btn1">视频上传协议</a></span>
      </div>

      <div class="box2">
        <p>校园虚拟导览系统, 将为千万高中生打开云窥象牙塔之窗, 让千万校友能云抱母校, 也使千万社会人士云游高校成为可能. 高校用户填写完整信息、开通视频上传权限后，某高校前几位上传视频的会员，可以自愿成为校园虚拟导览项目的“初创会员”，获得涉及您所在学校的视频的管理审核权限，为网站建设、维护网络环境、展现母校风采作出贡献。初创会员不仅是认可、荣誉和身份，更是一项责任于担当。</p>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { applySetFirst } from "@/api/Mange/Mange";
export default {
  data() {
    return {
      agreeChecked: false,
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    handleApplyFirst() {
      if (this.agreeChecked == false) {
        this.$message.warning("请勾选用户协议");
      } else {
        applySetFirst() .then((res) => {
            if(res.status){
              this.$message.success('已申请, 等待审核');
            }else{
              this.$message.warning(res.msg);
            }
            this.handleClose();
          })
          .catch(() => {
            this.$toast("获取服务器认证失败");
          });
      }
    },
  },
};
</script>

<style lang="less">
.password {
  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }

  .btn1 {
    color: #d14649;
    font-size: 12px;
    display: inline-block;
    line-height: 60px;
  }
  .box {
    p {
      color: #fff;
      line-height: 30px;
    }
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      border: 1px solid #333;
      background-color: #0e092b;
    }
  }
  .box1 {
    font-size: 12px;
    .text {
      padding-left: 5px;
    }
  }
  .box2 {
    p {
      line-height: 30px;
    }
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 30%;
      height: 20px;
      margin: 0 15px 0 5px;
    }
    img {
      vertical-align: middle;
    }
  }
}
</style>
