<template>
  <div class="password">
    <el-dialog
      title="视频上传权限认证"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="box">
        <p>您就读学校是?</p>
        <el-select v-model="ruleForm.schoolId" placeholder="请选择" filterable >
          <el-option
            v-for="item in schoolsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box">
        <p>您的毕业年份是？</p>
        <el-select v-model="ruleForm.graduationYear" placeholder="请选择">
          <el-option
            v-for="(item, index) in yearsOptions"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box1">
        <el-checkbox v-model="agreeChecked"></el-checkbox>
        <span class="text">我已阅读并同意<a class="btn1" @click="showText">用户协议</a></span>
      </div>
      <div class="box2">
        <p>请输入右侧图中的字符，不区分大小写</p>
        验证码<input class="input" v-model="ruleForm.verifyCode" />
        <img :src="codeUrl" @click="getCode" />
      </div>

      <span slot="footer">
        <el-button @click="submitForm">提交</el-button>
      </span>
    </el-dialog>

    <applyFirst ref="applyFirst"></applyFirst>
    <showText ref="showText"></showText>

  </div>
</template>

<script>
import axios from "axios";

import { userAuth, schoolsOptions, hasFirst } from "@/api/Mange/Mange";
import applyFirst from "@/views/Manage/components/dialog/applyFirst.vue";
import showText from "@/views/Manage/components/dialog/text.vue";

export default {
  components: {
    applyFirst,
    showText
  },
  data() {
    return {
      agreeChecked: false,
      codeUrl: "",
      yearsOptions: [],
      schoolsOptions: [],
      dialogVisible: false,
      ruleForm: {
        schoolId: "",
        graduationYear: "",
        verifyCode: "",
      },
    };
  },
  created() {},
  methods: {
    showText(){
      console.log(this.$refs.showText)
      this.$refs.showText.showDialog();
    },
    getYear() {
      //设置年份的选择
      var myDate = new Date();
      var endYear = myDate.getFullYear() + 10; //结束年份
      for (var i = 1970; i <= endYear; i++) {
        this.yearsOptions.unshift(i);
      }
    },
    getShoolsOption() {
      schoolsOptions().then((res) => {
        if (res.status) {
          this.schoolsOptions = res.data;
        } else {
          this.$message(res.msg);
        }
      });
    },
    getCode() {
      axios({
        method: "get",
        url: `${process.env.VUE_APP_BASE_API}/api/verifyCode`,
        responseType: "blob",
      }).then((res) => {
        var blob = res.data;
        this.codeUrl = window.URL.createObjectURL(blob);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
      this.getCode();
      this.getShoolsOption();
      this.getYear();
    },
    submitForm() {
      if (this.ruleForm.schoolId == "") {
        this.$message("请选择学校");
      } else if (this.ruleForm.graduationYear == "") {
        this.$message("请选择年份");
      } else if (this.ruleForm.verifyCode == "") {
        this.$message("请输入验证码");
      } else if (this.agreeCheckedelse == false) {
        this.$message("请勾选用户协议");
      } else {
        userAuth(this.ruleForm)
          .then((res) => {
            if(res.status){
              this.$message('认证成功！');
              this.dialogVisible = false
              hasFirst(res.data.schoolId).then(res => {
                if (res.data) {
                  // 弹出申请初创
                  this.$refs.applyFirst.showDialog();
                }
              });
            }else{
              this.$message(res.msg);
            }

          })
          .catch(() => {
            this.$message("获取服务器认证失败");
          }); //捕获异常
      }
    },
  },
};
</script>

<style lang="less">
.password {
  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }

  .btn1 {
    color: #d14649;
    font-size: 12px;
    display: inline-block;
    line-height: 60px;
  }
  .box {
    p {
      color: #fff;
      line-height: 30px;
    }
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      border: 1px solid #333;
      background-color: #0e092b;
    }
  }
  .box1 {
    font-size: 12px;
    .text {
      padding-left: 5px;
    }
  }
  .box2 {
    p {
      line-height: 30px;
    }
    .input {
      padding: 5px 0;
      font-size: 14px;
      border: 1px solid #333;
      background-color: #0e092b;
      color: #fff;
      width: 30%;
      height: 20px;
      margin: 0 15px 0 5px;
    }
    img {
      vertical-align: middle;
    }
  }
}
</style>
