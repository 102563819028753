<template>
  <div class="systemSettins">
    <div class="main">
      <el-form size="mini" ref="form" :model="form" :rules="rules" label-width="120px">

        <el-form-item label="公开浏览" prop="email">
          <el-switch v-model="form.openView" active-color="#13ce66"></el-switch>
        </el-form-item>

        <el-form-item label="公开注册" prop="email">
          <el-switch v-model="form.openRegister" active-color="#13ce66"></el-switch>
        </el-form-item>

        <el-form-item label="联系方式(邮箱)" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="网站名称" prop="webName">
          <el-input v-model="form.webName"></el-input>
        </el-form-item>
        <el-form-item label="logo" prop="webLogo">
          <el-input v-model="form.webLogo"></el-input>
        </el-form-item>
        <el-form-item label="备案信息" prop="filings">
          <el-input v-model="form.filings"></el-input>
        </el-form-item>
        <el-form-item label="版权" prop="copyright">
          <el-input v-model="form.copyright"></el-input>
        </el-form-item>
        <el-form-item label="首页标题1" prop="indexBannerTitle1">
          <el-input v-model="form.indexBannerTitle1"></el-input>
        </el-form-item>
        <el-form-item label="首页标题2" prop="indexBannerTitle2">
          <el-input v-model="form.indexBannerTitle2"></el-input>
        </el-form-item>
        <el-form-item label="首页视频" prop="indexBannerVideo">
          <el-input v-model="form.indexBannerVideo"></el-input>
        </el-form-item>
        <el-form-item label="团队栏目视频" prop="teamVideo">
          <el-input v-model="form.teamVideo"></el-input>
        </el-form-item>
        <el-form-item label="项目理念标题" prop="projectTitle">
          <el-input v-model="form.projectTitle"></el-input>
        </el-form-item>
        <el-form-item label="项目理念图片" prop="projectImage">
          <el-input v-model="form.projectImage"></el-input>
        </el-form-item>
        <el-form-item label="项目理念内容" prop="projectContent">
          <el-input type="textarea" rows="4" v-model="form.projectContent"></el-input>
        </el-form-item>
        <el-form-item label="致谢与致歉标题" prop="thankTitle">
          <el-input v-model="form.thankTitle"></el-input>
        </el-form-item>
        <el-form-item label="致谢与致歉内容" prop="thankContent">
          <el-input type="textarea" rows="2" v-model="form.thankContent"></el-input>
        </el-form-item>
        <el-form-item label="团队标题" prop="aboutTitle">
          <el-input v-model="form.aboutTitle"></el-input>
        </el-form-item>
        <el-form-item label="团队内容" prop="aboutContent">
          <el-input type="textarea" rows="4" v-model="form.aboutContent"></el-input>
        </el-form-item>
        <el-form-item label="致师生校友标题" prop="toTeacherTitle">
          <el-input v-model="form.toTeacherTitle"></el-input>
        </el-form-item>
        <el-form-item label="致师生校友内容" prop="toTeacherContent">
          <el-input type="textarea" rows="6" v-model="form.toTeacherContent"></el-input>
        </el-form-item>
        <el-form-item label="如何加入标题" prop="joinOusTitle">
          <el-input v-model="form.joinOusTitle"></el-input>
        </el-form-item>
        <el-form-item label="加入我们内容" prop="joinOusContent">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 300px; overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import { settingsDetail, settingsSave } from "@/api/Mange/Mange";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar},
  data() {
    return {
      form: {
        openView: '',
        openRegister: '',
        email: '',
        webName: '',
        webLogo: '',
        filings: '',
        copyright: '',
        indexBannerTitle1: '',
        indexBannerTitle2: '',
        indexBannerVideo: '',
        teamVideo: '',
        projectTitle: '',
        projectImage: '',
        projectContent: '',
        thankTitle: '',
        thankContent: '',
        aboutTitle: '',
        aboutContent: '',
        toTeacherTitle: '',
        toTeacherContent: '',
        joinOusTitle: '',
        joinOusContent: '',
      },
      rules: {
        openView: [{required: true, message: '此项不能为空', trigger: 'change'}],
        openRegister: [{required: true, message: '此项不能为空', trigger: 'change'}],
        email: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        webName: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        webLogo: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        filings: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        copyright: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        indexBannerTitle1: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        indexBannerTitle2: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        indexBannerVideo: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        teamVideo: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        projectTitle: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        projectImage: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        projectContent: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        thankTitle: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        thankContent: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        aboutTitle: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        aboutContent: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        toTeacherTitle: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        toTeacherContent: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        joinOusTitle: [{required: true, message: '此项不能为空', trigger: 'blur'}],
        joinOusContent: [{required: true, message: '此项不能为空', trigger: 'blur'}],
      },
      editor: null,
      html0: '',
      html: '',
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          uploadImage: {
            server: process.env.VUE_APP_BASE_API + "/api/uploadForEditor",
          }
        }
      },
      mode: 'default', // or 'simple'
    };
  },
  created() {
    settingsDetail().then(res => {
      this.form.openView = res.data.openView;
      this.form.openRegister = res.data.openRegister;
      this.form.email = res.data.email;
      this.form.webName = res.data.webName;
      this.form.webLogo = res.data.webLogo;
      this.form.filings = res.data.filings;
      this.form.copyright = res.data.copyright;
      this.form.indexBannerTitle1 = res.data.indexBannerTitle1;
      this.form.indexBannerTitle2 = res.data.indexBannerTitle2;
      this.form.indexBannerVideo = res.data.indexBannerVideo;
      this.form.teamVideo = res.data.teamVideo;
      this.form.projectTitle = res.data.projectTitle;
      this.form.projectImage = res.data.projectImage;
      this.form.projectContent = res.data.projectContent;
      this.form.thankTitle = res.data.thankTitle;
      this.form.thankContent = res.data.thankContent;
      this.form.aboutTitle = res.data.aboutTitle;
      this.form.aboutContent = res.data.aboutContent;
      this.form.toTeacherTitle = res.data.toTeacherTitle;
      this.form.toTeacherContent = res.data.toTeacherContent;
      this.form.joinOusTitle = res.data.joinOusTitle;
      this.form.joinOusContent = res.data.joinOusContent;

      this.html = this.form.joinOusContent;
    })
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    onSubmit() {

      this.form.joinOusContent = this.html;

      this.$refs["form"].validate(valid => {
        if (valid) {
          settingsSave(this.form).then(() => {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          })
        }
      });
    },
  },
};
</script>

<style lang="less">
.systemSettins {
  background-color: #0e092b;
  height: 100%;
  .titleH3 {
    color: #fff;
    font-size: 56px;
    font-weight: normal;
    padding: 1px 0 20px 0;
    margin-left: 85px;
    margin-top: 80px;
  }
  .main {
    margin-left: 60px;
    width: 80%;

    padding-left: 30px;
    padding-top: 20px;
    .table td,
    th {
      background-color: #0e092b;
      color: #fff;
    }
    .table .el-table tr:hover {
      background-color: #000;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgb(13 13 17);
      color: #fff;
    }
    .el-input__inner {
      //background-color: #0e092b;
      //color: #fff;
      border: none;
    }
    .btn {
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      display: inline-block;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .reason-row {
    margin: 10px 0;
    color: #fff;
  }
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
